.flexbox {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	display: box;
}

.flexbox.pad-lr-15 > .box {
	padding: 0 15px;
}

.flexbox-stretch {
  -webkit-align-items: stretch;
  align-items: stretch;
}

.box {
	-webkit-align-self: center;
	align-self: center;
}

.flexbox.left-0 .box {
  padding-left: 0px !important;
}

.box-flex-end {
  -webkit-align-self: flex-end;
  align-self: flex-end;;
}

.flexbox-v-center {
  -webkit-justify-content: center;
  justify-content: center;
}

.flexbox-h-center {
  -webkit-align-items: center;
  align-items: center;
}

.flexbox-right {
  justify-content: flex-end;
}

.grow-2 {
	-webkit-flex-grow: 2;
	flex-grow: 2;
}

.inherit {
	display: inherit;
}

.space-between {
	justify-content: space-between;
}

.flexbox.flex-pad-15 .box {
	padding: 15px;
}

.flex-wrap {
	flex-wrap: wrap;
}
