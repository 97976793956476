.text-xl {
  font-size: 2.0em
}

.text-lg {
  font-size: 1.6em;
}

.text-md {
  font-size: 1.3em;
}

.text-sm {
  font-size: 0.9em;
}

.text-xs {
  font-size: 0.8em;
}

.text-upper {
  text-transform: uppercase;
}

.text-medium {
  font-weight: 600
}

.text-bold {
  font-weight: bold;
}

.text-light {
  font-weight: lighter;
}

.text-normal {
  font-weight: normal;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
  text-align: right;
}
