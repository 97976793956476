.photo-cortina {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
}
.photo-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 101;
  top: 0;
}
.photo-content {
  padding: 50px;
  width: 100%;
  height: 100%;
  position: relative;
}
.photo-content img {
  max-width: 100%;
  max-height: 100%;
}
.photo-modal-close {
  cursor: pointer;
  position: relative;
  bottom: 50px;
  color: #fff;
  font-weight: 600;
}
.photo-modal-close i {
  font-size: 30px;
  color: #fff;
}
