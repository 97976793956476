.card {
  background-color: #fff;
  margin-bottom: 15px;
}

.card.card-border {
  border: 1px solid #E0E0E0;
}

.card.card-radius {
  border-radius: 5px;
}

.card .card-body {
  padding: 15px;
}

.card.bg-primary:hover {
  background: #ccc;
  transition: 0.3s;
  color: #555;
}

.card.pad-30 {
  padding: 30px 15px;
}

.vFull {
  height: 100%;
}

.h100 {
  height: 100px;
}

.h120 {
  height: 120px;
}
