.login-container {
  width: 100%;
  height: 100%;
}

html, body {
  height: 100%;
}

.login-card-box {
  width: 400px;
}
