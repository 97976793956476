.brand {
	height: 220px;
}

.fill-height {
	height: 100%;
}

.fill-width {
	width: 100%;
}

.container-right {
	height: 100%;
	overflow-x: auto;
	padding: 15px;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0px 0px 15px 0px;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-30 {
	margin-bottom: 30px;
}

.badge-md {
	border-radius: 6px;
}

.ck {
	cursor: pointer;
}

.margin-0 {
	margin: 0 !important;
}

.margin-5 {
	margin: 5px;
}

.table tr.active > td {
	background-color: #555 !important;
}

tr.active > td,
tr.active > td .icon {
	color: #fff !important;
}

.a-middle td,
.a-middle th {
	vertical-align: middle !important;
}

.gb-table tr {
	height: 45px;
}

.block {
	display: block;
}

.inline {
	display: inline;
}

.inline>li {
	vertical-align: middle;
}

.separator-right {
	border-right: 1px solid #ccc;
}

.pad-none {
	padding: 0px !important;
}

.full {
	width: 100%;
}


/*SWITCH*/
.switch {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}

.switch + label {
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
	margin: 0px 5px 0px 0px;
}

.switch--shadow + label {
  padding: 2px;
  width: 38px;
  height: 20px;
  background-color: #dddddd;
  border-radius: 10px;
}

.switch--shadow + label:before,
.switch--shadow + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}
.switch--shadow + label:before {
  right: 1px;
  background-color: #f1f1f1;
  border-radius: 10px;
  transition: all 0.4s;
}
.switch--shadow + label:after {
  width: 18px;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.4s;
}

.switch--shadow:checked + label:before {
  background-color: #5cb85c;
  border-color: #60b36a;
}
.switch--shadow:checked + label:after {
	border-color: #60b36a;
  transform: translateX(18px);
}

/*SIDEMENU*/

.side-menu {
	height: calc(100% - 220px);
}

.side-menu a {
	text-decoration: none;
	color: #fff;
}

.side-menu .side-item {
	padding: 15px;
	height: 100%;
	width: 100%;
}

.side-menu .side-item:hover {
	background-color: #999;
}

.side-menu .side-item.active {
	background-color: #555;
}

.side-menu li.list-item {
	padding: 0px;
}

.side-menu a .icon {
	display: none;
}

.side-menu a.active .icon {
	display: block;
}

.side-menu .side-menu-secundary a {
	padding: 15px 15px 15px 45px;
	height: 100%;
	width: 100%;
}

.no-border {
	background-color: #fff;
	outline: 0
}
.no-border:not([readonly]){
	border: 1px solid #eee;
}
.no-border:read-only {
	border: 0;
}
.no-border:-moz-read-only {
	border: 0;
}
