.bg-primary {
  background-color: #555;
}

.bg-primary-light {
  background-color: #777;
  color: #fff;
}

.bg-secundary {
  background-color: #ccc;
}

.bg-white {
  background-color: #fff;
}

.color-primary {
  color: initial;
}

.white {
  color: #fff;
}

.icon-muted {
  color: #8e8e8e;
}

.icon-muted:hover {
  color: #5e5e5e;
  transition: 0.3s;
}

.text-danger {
  color: #a94442 !important;
}
