/*FORMATAÇÃO DE LISTA*/

.list-group {
	margin: 0;
	list-style: none;
	padding: 0px;
}

.list-group .list-item {
	position: relative;
	display: table;
	width: 100%;
	padding: 15px 0;
}

.list-group .list-no-items {
	position: relative;
	display: table;
	width: 100%;
	padding: 15px 0;
	text-align: center;
}

.list-item.pad-30 {
	padding: 15px 30px;
}

.list-item.pad-15 {
	padding: 15px;
}

.list-group.list-card .list-item {
	outline: 1px solid #888;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.list-group.list-divider .list-item {
	border-bottom: 1px solid #ccc;
}

.list-group.list-divider li:nth-last-of-type(1) {
	border-bottom: 0px;
	border-radius: 4px;
}

.list-group .list-item .item-full {
	width: 100%;
}

.list-item .item-full.pad {
  padding: 15px;
}

.list-group .list-item .item-image {
	width: 70px;
	float:left;
  padding: 15px;
}

.list-group .list-item .item-secundary {
	width: calc(100% - 70px);
	float:left;
  padding: 15px;
}

.list-group .list-item .item-status {
	position: absolute;
	top: 5px;
	right: 0px;
}

.list-inline > div, .list-inline > li {
	padding-right: 5px;
	padding-left: 5px;
	display: inline-block;
	vertical-align: middle;;
}

.list-inline.not-pad > div, .list-inline.not-pad > li {
	padding-right: 20px;
	padding-left: 0;
	display: inline-block;
	vertical-align: middle;;
}

.list-inline.icons > div:last-child, .list-inline.icons > li:last-child {
	padding-right: 0px;
}

.inline-center {
  vertical-align: middle;
}

.tags .label {
  margin-right: 5px;
}

.icon-action-hover {
	display: none;
}

.list-item:hover .icon-action-hover {
	display: block;
}

.list-group > .list-item:hover {
	background-color: #eaeaea;
}

.list-item.active {
	background-color: #555;
	color: #fff;
}

.list-maxsize-md {
	overflow: auto;
	max-height: 350px;
}

.selected {
	background-color: #CCC;
}

.dropdown-divider {
  height: 0;
  margin: 3px 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
